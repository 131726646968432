



























import Vue from "vue";
import { Toast, Notify } from "vant";
import { mapState } from "vuex";
import { dispatch, RootState } from "@/store";
import { url } from "@/api";

export default Vue.extend({
  data() {
    return {
      phone: "",
      code: "",
      time: 0,
    };
  },
  computed: {
    ...mapState("app", {
      isInWeChat: "isInWeChat",
    }),
    ...mapState("profile", {
      uuid: "uuid",
    }),
    redirect(): string {
      if (this.$route.query.redirect) {
        return this.$route.query.redirect + "";
      }
      return this.$paths.home;
    },
    sendText(): string {
      if (this.time) {
        return this.time + "秒后重新发送";
      } else {
        return "发送短信验证码";
      }
    },
  },
  methods: {
    onSendCode() {
      const { phone, time } = this;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        Toast("请填写正确的手机号码");
        return false;
      } else if (!time) {
        dispatch.appSendSMS(phone).then(() => {
          (this.$refs.code as HTMLInputElement).focus();
          if (this.time) return;
          Notify("短信验证码已发送，请注意查收");
          this.time = 60;
          const timer = setInterval(() => {
            if (this.time === 1) {
              clearInterval(timer);
            }
            this.time--;
          }, 1000);
        });
      }
    },
    onCodeInput(e: InputEvent) {
      const value = (e.target as HTMLInputElement).value.slice(0, 6);
      this.code = value;
    },
    onPhoneSignIn() {
      // const { phone, code } = this;
      // if (!/^1[3456789]\d{9}$/.test(phone)) {
      //   Toast("请填写正确的手机号码");
      //   return false;
      // } else if (!code) {
      //   Toast("请将验证码填写完整");
      //   return false;
      // } else {
      //   dispatch
      //     .appPhoneSignIn({ phone, code })
      //     .then(() => {
      //       window.location.href = this.redirect;
      //     })
      //     .catch(() => {
      //       this.$router.replace(this.$paths.auth);
      //     });
      // }
      const origin = window.location.origin;
      window.open(
        url.auth +
          "?redirect=" +
          encodeURIComponent(origin + this.redirect.replace(origin, "")),
        "_self"
      );
    },
  },
  watch: {
    uuid(uuid) {
      if (uuid) {
        (window as any).location.href = this.redirect;
      }
    },
  },
});
